import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import Logo from "../../components/logo"

const StyledPage = styled.nav`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .body {
    background-color: #c0c0c0;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: Acumin Pro, acumin-pro, sans-serif;
    overflow: hidden;
  }

  .background {
    position: absolute;
    height: 210vh;
    filter: brightness(0) opacity(0.025);
  }

  .card {
    width: calc(85px * 6);
    height: calc(55px * 6);
    background: linear-gradient(145deg, #adadad, #cdcdcd);
    background: white;
    box-shadow: 14px 14px 50px #a3a3a3, -14px -14px 50px #dddddd;
    box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    padding: 2rem;
    position: relative;

    &__name {
      font-size: 3rem;
      // margin-bottom: 1rem;
      margin-bottom: 0.5rem;
      line-height: 1.2;
      font-weight: 600;
    }

    &__position {
      font-size: 1rem;
      // margin-bottom: 2.5rem;
      // color: var(--gray);
      font-weight: bold;
    }

    .wrapper {
      position: absolute;
      bottom: 1rem;
    }

    &__mail {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    &__phone {
      font-size: 1rem;
      margin-bottom: 0.5rem;

      .fas {
        width: 0.75rem;
        margin-right: 0.5rem;

        &::before {
          color: var(--primary);
        }
      }
    }

    &__photo {
      width: 155px;
      height: 155px;

      border-radius: 1rem;
      overflow: hidden;
      position: absolute;
      top: 2rem;
      right: 2rem;
      cursor: pointer;

      &:hover {
        .download {
          height: 100% !important;
        }
      }

      .download {
        height: 0%;
        color: var(--primary);
        transition: height 0.5s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: vcard-glance 0.5s 1s ease-in-out backwards;

        .fas {
          font-size: 7rem;
        }
      }
    }

    &__logo {
      position: absolute;
      bottom: 1.5rem;
      right: 2rem;
      width: 39%;
      // clip-path: polygon(5% 30%, 5% 66%, 91% 66%, 91% 30%);
    }
  }

  .text-primary {
    color: var(--primary);
  }

  a {
    color: black;
    text-decoration: none;

    &:hover {
      color: var(--primary);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @keyframes vcard-glance {
    from {
      height: 100%;
    }
    to {
      height: 0%;
    }
  }
`

const HelmutVCardPage = () => (
  <StyledPage>
    <div class="body">
      {/* <div class="background">
        <img src="./img/logo.svg" alt="" />
      </div> */}
      <div class="card">
        <div class="card__name">
          Helmut
          <br />
          Kraft
        </div>
        <div class="card__position">CEO and Founder</div>
        <div class="wrapper">
          <div class="card__mail">
            <a href="mailto:h.kraft@spritzgiessfuehrerschein.de">
              h.kraft@spritzgiessfuehrerschein.de
            </a>
          </div>
          <div class="card__phone">
            <i class="fas fa-mobile-alt text-primary"></i>
            <a href="tel:+491702331177">+49 170 2331177</a>
          </div>
        </div>
        <div class="card__photo">
          <a href="https:///vcard.i40.de/helmutkraft/vcard_sz.vcf">
            <div class="download">
              <i class="fas fa-cloud-download-alt"></i>Download vCard
            </div>
          </a>

          <img
            src="https://vcard.i40.de/helmutkraft/img/helmut.jpg"
            alt="Helmut Kraft"
            draggable="false"
          />
        </div>
        <div class="card__logo">
          <a
            href="https://www.spritzgiessfuehrerschein.de"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Logo />
            {/* <img src="./img/logo_wide.svg" alt="i40 Logo" draggable="false" /> */}
          </a>
        </div>
      </div>
    </div>
  </StyledPage>
)

export default HelmutVCardPage
